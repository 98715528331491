<!-- eslint-disable -->
<template>
  <div class="page_box">
    <se-breadcrumb :itemData="breadData" showBottomBorder> </se-breadcrumb>
    <div class="data_table_box">
      <my-table ref="list" :data="tableList" @page="pageHandler" :listLoading="listLoading">
        <!-- 表格数据搜索 -->
        <template #header="params">
          <el-tabs v-model="tabName" @tab-click="(e)=>tabChange(e, params, 1)">
            <el-tab-pane label="兑换购买数量" name="1"></el-tab-pane>
<!--            <el-tab-pane label="兑换购买单数" name="2"></el-tab-pane>-->
          </el-tabs>
          <div class="search_box">
            <el-row :gutter="10">
              <el-col :span="6" v-if="tabName==1||tabName==3">
                <el-input placeholder="请输入手机号" v-model="searchData.customerMobile" class="input-with-select"></el-input>
              </el-col>
              <el-col :span="8"  v-if="tabName==2||tabName==3">
                <el-date-picker
                  style="width: 100%;"
                  v-model="activeDateTime"
                  type="daterange"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="pickerDateChange"
                  :picker-options="pickerOptions">
                </el-date-picker>
              </el-col>
              <el-col :span="4">
                <el-button @click="resetSearchData">重置</el-button>
                <el-button ref="searchButton" @click="pageHandler(params, 1)" type="primary">查询</el-button>
              </el-col>
            </el-row>
          </div>
        </template>

        <span v-for="(item) in tableData" :key="Math.random()">
          <el-table-column v-if="item.prop == 'customerMobile'" :label="item.label" :prop="item.prop" :min-width="item.width">
            <template slot-scope="scope">
              <el-button type="text" @click="handleUserView(scope.row)" class="blue-btn">{{ scope.row.customerMobile }}</el-button>
            </template>
          </el-table-column>

          <el-table-column v-else :label="item.label" :prop="item.prop" :min-width="item.width"></el-table-column>
        </span>
<!--        <el-table-column label="操作" fixed="right" prop="" width="100">-->
<!--          <template slot-scope="scope">-->
<!--             <el-button v-if="tabName == 1 && scope.row.canRefund ==1 " class="handle_btn" type="text" size="mini" @click="returnCard(scope.row)">退单</el-button>-->
<!--          </template>-->
        </el-table-column>
      </my-table>
    </div>
    <el-dialog
      title="退卡"
      :visible.sync="cardDialogVisible"
      width="70%"
      :before-close="cardHandleClose">
      <div class="table_box">
        <div class="table_title">套餐卡</div>
        <el-table
          v-for="card in orderCardData"
          :data="card.serviceList"
          border
          style="width: 100%">
          <el-table-column
            prop="serviceName"
            label="项目名称"
            width="180">
          </el-table-column>
          <el-table-column
            label="剩余次数/总次数"
            width="180">
            <template slot-scope="scope">{{ scope.row.residueCount }}/{{ scope.row.totalCount }}</template>
          </el-table-column>
          <el-table-column
            prop="carryingBalance"
            label="账面余额（元）">
          </el-table-column>
        </el-table>
      </div>
<!--      <div class="table_box">-->
<!--        <div class="table_title">优惠券</div>-->
<!--        <el-table-->
<!--          :data="orderCouponData"-->
<!--          border-->
<!--          style="width: 100%">-->
<!--          <el-table-column-->
<!--            prop="date"-->
<!--            label="优惠券名称">-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--            prop="name"-->
<!--            label="优惠券状态"-->
<!--            width="180">-->
<!--          </el-table-column>-->
<!--        </el-table>-->
<!--      </div>-->
      <div class="refund-form">
        <el-form
            label-position="right"
            :rules="refundFormRule"
            label-width="auto"
            ref="refundForm"
            :model="refundForm"
        >
          <el-form-item label="实际退款金额" prop="returnAmount">
            <se-input-unit unitName="元" v-model="refundForm.returnAmount"></se-input-unit>
          </el-form-item>
          <el-form-item label="退单原因" prop="returnRemark">
            <el-input unitName="元" v-model="refundForm.returnRemark"></el-input>
          </el-form-item>
          <el-form-item label="支付方式" prop="returnMethod">
            <el-select v-model="refundForm.returnMethod">
              <el-option
                  v-for="item in payWayList"
                  :label="item.payMethodName"
                  :value="item.payMethodCode"
                  :key="item.payMethodCode"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="tips"></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cardDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="returnCardConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import t from "@common/src/i18n/t";
import {namespace} from "vuex-class";
const marketing = namespace("marketing");
import { emptyToLine, splitThousands } from "@/utils/common";


export default {
  // emptyToLine = emptyToLine;
  // splitThousands = splitThousands;
  //import引入的组件需要注入到对象中才能使用
  components: {},
    data() {
    //这里存放数据
      return {
        packageCode: '',
        activityCode: '',                                   //活动code
        activityName: '',                                   //活动名称
        tabName: '1',                                       //列表标签页
        searchData: {                                       //查询数据
          customerMobile: '',
          dateStart: '',
          dateEnd: ''
        },
        tableList: [{}],                                      //列表数据
        listLoading: false,                                 //列表loading
        activityDetailData: [                               //活动参与明细展示字段
          { label: "用户姓名", width: 100, prop: "userName" },
          { label: "订单号", width: 160, prop: "orderNo" },
          { label: "手机号", width: 100, prop: "mobile" },
          { label: "支付方式", width: 150, prop: "paidMethodName" },
          { label: "支付状态", width: 150, prop: "stateName" },
          { label: "金额", width: 100, prop: "paymentPrice" },
          { label: "数量", width: 180, prop: "quantity" },
          { label: "购买时间", width: 100, prop: "ctime" },
        ],
        activityDetailList: [{},{},{}],                           //活动参与明细table列表
        channelDetailData: [                                //渠道明细展示字段
          { label: "时间", width: 120, prop: "date" },
          { label: "渠道类型", width: 100, prop: "channel" },
          { label: "子类型", width: 100, prop: "linkName" },
          { label: "分享浏览次数", width: 100, prop: "shareBrowsePv" },
          { label: "分享浏览人数", width: 100, prop: "shareBrowseUv" },
          { label: "成交", width: 100, prop: "purchaseCount" },
          // { label: "总奖励金额", width: 100, prop: "" },
        ],
        channelDetailList: [{},{},{}],                            //渠道明细table列表
        userDetailData: [                                   //用户裂变明细展示字段
          { label: "时间", width: 120, prop: "date" },
          { label: "用户姓名", width: 100, prop: "customerName" },
          { label: "手机号", width: 100, prop: "customerMobile" },
          { label: "分享次数", width: 100, prop: "shareCount" },
          { label: "分享浏览次数", width: 100, prop: "shareBrowsePv" },
          { label: "分享浏览人数", width: 100, prop: "shareBrowseUv" },
          { label: "成交", width: 100, prop: "purchaseCount" },
          // { label: "总奖励金额", width: 100, prop: "" },
        ],
        userDetailList: [{},{},{}],                               //用户裂变明细table列表
        cardDialogVisible: false,                           //退卡弹窗状态
        activityAnalysisDetail: {},                     //活动概况数据
        activeDateTime: [],
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        orderCardData: [], // 购买订单的卡券信息
        orderCouponData: [],  //购买订单的优惠券信息

        refundFormRule: {
          returnAmount: [
            { required: true, message: "请输入实际退款金额", trigger: "blur" },
            { validator: this.checkPrice, trigger: "blur" }
          ],
          returnRemark: { required: true, message: "请输入退款原因", trigger: "blur" },
          returnMethod: { required: true, message: "请选择支付方式", trigger: "change" }
        },
        refundForm: {
          orderNo: "",
          returnAmount: "",
          returnMethod: "",
          returnRemark: ""
        },
        payWayList: [{
          payMethodName: "微信支付",
          payMethodCode: "3",
        }]

      };
    },
  //监听属性 类似于data概念
  computed: {
    breadData() {
      return [
        { name: "组合营销", path: "/combinedPackage/list" },
        {
          name: "数据面板"
        }
      ];
    }
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    pageHandler(params, num) {
      switch (this.tabName) {
        case '1':
          //兑换购买人数
          this.getPromoPackageUser(params, num ? num : params.pageNum, params.pageSize);
          break;
        case '2':
          break;
        default:
          break;
      }
    },
    // 表格内操作
    tableHandle(type, row) {

    },
    // 重置搜索数据
    resetSearchData(e) {
      this.searchData = {                                       //查询数据
        customerMobile: '',
        dateStart: '',
        dateEnd: ''
      }
      this.activeDateTime = [];
    },
    // 退卡
    returnCard(row){
      this.refundForm.orderNo = row.orderNo;
      this.orderCardData = [];
      this.getAnalysisDetail(row.orderNo);
      this.cardDialogVisible = true;
    },
    returnCardConfirm(){
      (this.$refs.refundForm).validate(valid => {
        if (valid) {
          this.$store.dispatch('marketing/newAnalysisRefund',{
            ...this.refundForm
          }).then(res => {
            this.$message.success("退单成功");
            this.$emit("returnCardSuccess");
            this.$refs.searchButton.$el.click();
            this.cardDialogVisible = false
          })
        }
      });
    },
    // 退卡弹窗关闭
    cardHandleClose(){
      this.cardDialogVisible = false;
    },
    // 标签点击
    tabChange(e, params, num){
      this.searchData = {                                       //查询数据
        customerMobile: '',
        dateStart: '',
        dateEnd: ''
      }
      this.activeDateTime = [];
      switch (e.name) {
        case '1':  //兑换购买人数
          this.getPromoPackageUser(params, num ? num : params.pageNum, params.pageSize).then(()=>{
            this.tabName = e.name;
          });
          break;
        case '2':


          break;
        case '3':
          break;

        default:
          break;
      }
    },
    // 时间范围选择
    pickerDateChange(e){
      this.searchData.dateStart = e[0];
      this.searchData.dateEnd = e[1];
    },

    // 获取用户购买人数
    async getPromoPackageUser(params,pageNum,pageSize){
      await this.$store.dispatch('management/getPromoPackageUser',{
        packageCode: this.packageCode,
        pageNum,
        pageSize
      }).then(res => {
        console.log(res)
        this.tableData = this.activityDetailData;
        this.tableList = res.data.list;
        params.render(res.data.total);
      })
    },
    handleUserView(row) {
      let { href } = this.$router.resolve({
        path: `/customer/customer-view/${row.customerMobile}`
      });
      window.open(href, "_blank");
    }

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.packageCode = this.$route.params.id;
    this.activityName = this.$route.query.activityName;
    // 默认展示tab和列表
    this.tabName = '1';
    this.tableData = this.activityDetailData;
    // this.tableList = this.activityDetailList;
    // // 获取活动概览数据
    // this.getPromoPackageUser();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
  //@import url(); 引入公共css类
  .page_box{
    padding: 0 20px 20px;
    box-sizing: border-box;
    .title {
      margin: 24px 0 20px 0;
      h2 {
        .look-coupon {
          color: $link-color;
          font-size: 14px;
          font-weight: 200;
          margin-left: 16px;
        }
      }
    }
    .analysis_box{
      min-width: 1300px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .analysis_item{
        background: #fff;
        .analysis_item_title{
          padding: 0px 15px;
          height: 40px;
          line-height: 40px;
          font-weight: bold;
          font-size: 16px;
          border-bottom: 1px solid #dfe4ed;
        }
      }
      .analysis_item_left{
        width: 902px;
        height: 330px;
        margin-right: 10px;
        .analysis_chart_box{
          width: 802px;
          height: 244px;
          margin: 20px auto;
          background: url(../../../assets/img/campain-analysis-bg.png) no-repeat;
          background-size: 100% 100%;
          position: relative;
          .num_box{
            height: 100%;
            position: absolute;
            left: 290px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            .num_item{
              display: flex;
              align-items: center;
              text-align: center;
              .num_share>:last-child{
                color: #8c8c8c;
              }
            }
            .num_item>div{
              width: 100px;
            }
            .num_item >div:first-child{
              margin-right: 165px;
              color: #fff;
              font-weight: bold;
              font-size: 16px;
            }
          }
          .probability_box{
            position: absolute;
            text-align: center;
            >div:last-child{
              font-size: 15px;
              font-weight: bold;
            }
            >div:first-child{
              color: #8c8c8c;
            }
          }
          .probability_1{
            left: 90px;
            top: 55px;
          }
          .probability_2{
            left: 90px;
            bottom: 65px;
          }
          .probability_3{
            top: 95px;
            left: -30px;
          }
        }
      }
      .analysis_item_right{
        flex: 1;
        height: 330px;
        .analysis_item_right1{
          height: 120px;
          margin-bottom: 10px;
          .analysis_item_right1_content{
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            font-weight: bold;
            color: #333;
          }
        }
        .analysis_item_right2{
          height: 200px;
          .analysis_item_right2_content{
            height: 160px;
            margin-top: 30px;
            color: #333;
            .item{
              text-align: center;
              font-size: 15px;
              font-weight: bold;
            }
            .down{
              margin-top: 20px;
              display: flex;
              align-items: center;
              justify-content: space-around;
              .item{
                font-size: 13px;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
    .data_table_box{
      min-width: 1300px;
      background: #fff;
      margin-top: 10px;
      .search_box{
        padding: 10px 20px;
        margin-bottom: -20px;
      }
    }
  }
  .table_box{
    margin-bottom: 20px;
    .table_title{
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
  ::v-deep .el-tabs__nav-wrap{
    padding-left: 15px !important;
  }
</style>
